<template>
  <div>
    <trac-tab-window 
    :sectionTitle="'Integrations'" 
    :subLinks="subLinks" 
  />
  </div>
</template>

<script>
import MixinForScrollToTop from "../../offline-module/mixins/TabbedMixin";
export default {
  mixins: [MixinForScrollToTop],
  data() {
    return {
      subLinks: [
        {
          name: "All Integrations",
          path: "/tabbed-integrations/all-integrations",
          selected: true,
          routerWindowClassString: "bg-white mt-12 p-12 big-shadow",
        },
        {
          name: "My Integrations",
          path: "/tabbed-integrations/my-integrations",
          selected: false,
          routerWindowClassString: "bg-white mt-12 p-12 big-shadow",
        },
      ],
    };
  },
  created() {
    console.log("Tpath: ", this.$route.path);
    const enteredPath = this.$route.path;
    const subLinkIndex = this.subLinks.findIndex(
      (link) => link.path === enteredPath
    );
    // debugger
    if (subLinkIndex > -1) {
      this.subLinks[subLinkIndex].selected = true;
    } else {
      // do nothing
    }
  },
};
</script>

<style lang="scss" scoped>
</style>